import ScriptLoader from '~src/utils/script-loader'
import { ListerPageMethods } from './types/tweakwise/lister-page'
import { IApiNavigation } from './types/tweakwise/api-response'

/**
 * Tweakwise Configuration
 * @link https://docs.tweakwise.com/docs/js-js-implementation-local-configuration
 */

function initTweakwise() {
  if ('tweakwiseConfig' in window) {
    window['twn-starter-config'] = window['twn-starter-config'] || {}
    window['twn-starter-config'].auto = false
    window['twn-starter-config'].input = null

    let listerPage: ListerPageMethods = null

    window.addEventListener('twn.starter.ready', function () {
      let selection = getSelection()

      listerPage = window.tweakwiseListerPage({
        output: '#tweakwise-output',
        cid: window.tweakwiseConfig.cid,
        query: null,
        selection: selection.selection,
        on: {
          'twn.request.success': updatePageTitle,
        },
      })

      if (listerPage) listerPage.setOpen(true)
    })

    window.addEventListener('twn.suggestions.ready', function () {
      window.tweakwiseSuggestions({
        instancekey: window.tweakwiseConfig.instancekey,
        input: '#tweakwise-input',
        cid: window.tweakwiseConfig.cid,
        searchPhrases: {
          handle(event) {
            location.href = `${window.tweakwiseConfig.url}#twn|?tn_q=${event.data.match}`
            if (listerPage) listerPage.setQuery(event.data.match)
          },
        },
      })
    })
  }
}

function getSelection() {
  let selection: any = {}
  let decodedUrl = decodeURI(location.hash)

  if (decodedUrl.indexOf('twn|?') >= 0) {
    let hash = decodedUrl.split('#twn|?')
    let hashSplit = hash[1].split('&')
    hashSplit.forEach((o) => {
      let x = o.split('=')
      selection[x[0]] = x[1]
    })

    return { enabled: true, selection }
  }

  return { enabled: false, selection }
}

function updatePageTitle({ data }: { data: IApiNavigation }) {
  const titleNode = document.querySelector('.js-tw-title')
  const searchTerm = data.properties?.searchterm

  if (titleNode && searchTerm) {
    titleNode.textContent = `Zoekresultaten voor "${searchTerm}"`
  }
}

ScriptLoader.loadScript(
  `https://gateway.tweakwisenavigator.net/js/${window.tweakwiseConfig.instancekey}/tweakwise.js`,
  'tweakwise'
)
  .then(() => initTweakwise())
  .catch(() => console.log('Loading Tweakwise script failed'))
